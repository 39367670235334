<template>
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 tabvertical">
    <div class="col float-right">
      <q-btn-group outline>
        <q-chip
          v-if="(!preStartupCheck.equipments || preStartupCheck.equipments.length === 0) && Boolean(param.sopPrestartupCheckId)"
          class="no-border"
          style="margin-right:5px !important" 
          square outline
          color="red" 
          text-color="white" 
          icon="report" 
          size="12px" >
          {{$language('가동전점검표, 보고서는 하나 이상의 설비 점검표가 만들어져야 이용 가능합니다.')}}
        </q-chip>
        <c-btn 
          v-show="Boolean(param.sopPrestartupCheckId)" 
          :disabled="!preStartupCheck.equipments || preStartupCheck.equipments.length === 0"
          label="가동전점검표" 
          icon="print"
          @btnClicked="checklistPrint" />
        <c-btn 
          v-show="Boolean(param.sopPrestartupCheckId)" 
          :disabled="!preStartupCheck.equipments || preStartupCheck.equipments.length === 0"
          label="가동전안전점검보고서" 
          icon="print"
          @btnClicked="checklistReportPrint" />
        <c-btn v-show="editInfo && !disabled && Boolean(param.sopPrestartupCheckId)" label="삭제" icon="remove" @btnClicked="removePreStartupCheck" />
        <c-btn 
          v-show="editInfo && !disabled && Boolean(param.sopPrestartupCheckId)" 
          :isSubmit="isComplete"
          :url="saveUrl"
          :param="preStartupCheck"
          mappingType="PUT"
          label="완료" 
          icon="check"
          @beforeAction="completePreStartupCheck"
          @btnCallback="completeCallback" />
      </q-btn-group>
    </div>
    <c-tab
      type="vertical"
      :tabItems="tabItems"
      :height="tabHeight"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :key="tab.key"
          :param="param"
          :preStartupCheck.sync="preStartupCheck"
          :research.sync="research"
          :equipTab.sync="equipTab"
          :editInfo.sync="editInfo"
          :disabled.sync="disabled"
          :contentHeight.sync="contentHeight"
          @closePopup="closePopup"
          @setRegInfo="setRegInfo"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
import { uid } from 'quasar'
import transactionConfig from '@/js/transactionConfig';
import selectConfig from '@/js/selectConfig';
export default {
  name: 'prestartup-tab',
  props: {
    param: {
      type: Object,
      default: () => ({
        sopPrestartupCheckId: '',
        psrCheckStatusCd: '',
        isSearch: false,
      }),
    },
    research: {
      type: Object,
      default: () => ({
        impr: '',
        item: '',
      }),
    },
    preStartupCheck: {
      type: Object,
      default: () => ({
        sopPrestartupCheckId: '',  // 가동전점검 일련 번호
        plantCd: null,  // 사업장코드
        checkName: '',  // 점검명
        checkPurpose: '',  // 점검 목적
        psrEquipmentStatusCd: null,  // 설비 상태
        checkDeptCd: null,  // 점검부서
        sopMocId: '',  // MOC 일련번호
        checkCancarrierId: '',  // 점검 책임자
        checkResultTotal: '',  // 점검결과 종합
        psrCheckStatusCd: '',  // 진행 단계
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        equipments: [],
        deleteEquipments: [],
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      tab: 'baseInfo',
      editable: true,
      isComplete: false,
      saveUrl: '',
      equipTab: '',
    };
  },
  computed: {
    editInfo() {
      return this.editable && !this.param.isSearch
    },
    isOld() {
      return Boolean(this.param.sopPrestartupCheckId)
    },
    disabled() {
      // 점검완료인 경우 비활성화
      return this.preStartupCheck.psrCheckStatusCd === 'PCSC000005';
    },
    tabItems() {
      let items = [{ name: 'baseInfo', icon: 'edit', label: '기본정보', component: () => import(`${'./preStartupReviewInfo.vue'}`), key: uid() },]
      if (this.isOld) {
        items.push({ name: 'ctoChecklist', icon: 'how_to_reg', label: '설비별 점검', component: () => import(`${'./preStartupReviewCheckList.vue'}`), key: uid() })
      }
      return items;
    },
    tabHeight() {
      return String(this.contentHeight - 70);
    },
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    this.init();
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sop.psr.check.get.url;this.checklistPrintUrl = selectConfig.sop.psr.check.checklistPrint.url
      this.checklistReportPrintUrl = selectConfig.sop.psr.check.checklistReportPrint.url
      this.deleteEquipUrl = transactionConfig.sop.psr.check.equipment.delete.url
      this.saveUrl = transactionConfig.sop.psr.check.update.url
      // code setting
      // list setting
      this.getPreStartupCheck();
    },
    getPreStartupCheck() {
      if (this.param.sopPrestartupCheckId) {
        this.$http.url = this.$format(this.detailUrl, this.param.sopPrestartupCheckId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.preStartupCheck, _result.data)
          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _result.data)
          // this.preStartupCheck = _result.data;
          this.param.psrCheckStatusCd = this.$_.clone(this.preStartupCheck.psrCheckStatusCd)
          // this.$_.extend(this.preStartupCheck, _result.data);

          if (this.preStartupCheck.equipments && this.preStartupCheck.equipments.length > 0) {
            this.equipTab = this.preStartupCheck.equipments[0].equipmentCd;
            this.$_.forEach(this.preStartupCheck.equipments, item => {
              item.name = item.equipmentCd;
              item.label = item.equipmentName;
              item.icon = 'precision_manufacturing'
              item.component = () => import(`${'./preStartupFacility.vue'}`)
              item.key = uid()
              // this.tabItems.push({ name: item.equipmentCd, icon: 'precision_manufacturing', label: item.equipmentName, component: () => import(`${'./preStartupFacility.vue'}`) })
            })
          }

        },);
      }
    },
    closePopup() {
      this.$emit('closePopup')
    },
    setRegInfo(data) {
      this.$emit('setRegInfo', data)
    },
    
    completePreStartupCheck() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          if (!this.preStartupCheck.checkResultTotal) {
            window.getApp.$emit('ALERT', {
              title: '안내',
              message: '점검결과종합이 입력되지 않았습니다.',
              type: 'warning', // success / info / warning / error
            });
          } else if (!this.preStartupCheck.equipments || this.preStartupCheck.equipments.length === 0) {
            window.getApp.$emit('ALERT', {
              title: '안내',
              message: '하나 이상의 점검표가 만들어져야 합니다.', 
              type: 'warning', // success / info / warning / error
            });
          } else {
            let isEquipComplete = true;
            let isImprComplete = true;
            let message = '점검완료하시겠습니까?'; 
            this.$_.forEach(this.preStartupCheck.equipments, equip => {
              if (equip.checkCompleteFlag !== 'Y') {
                isEquipComplete = false
                return false;
              }

              this.$_.forEach(equip.checkItems, checkItem => {
                if (this.$_.findIndex(checkItem.improves, impr => {
                  return impr.ibmStepCd !== 'IS00000020'
                }) > -1) {
                  isImprComplete = false;
                  return false;
                }
              })
              if (!isImprComplete) {
                return false;
              }
            })

            if (!isEquipComplete) {
              window.getApp.$emit('ALERT', {
                title: '안내',
                message: '점검이 완료되지 않은 설비가 있습니다.', 
                type: 'warning', // success / info / warning / error
              });
              return;
            }
            if (!isImprComplete) {
              message = '개선 진행중인 항목이 존재합니다. 점검완료하시겠습니까?' 
            }
            window.getApp.$emit('CONFIRM', {
              title: '확인',
              message: message,
              
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.preStartupCheck.chgUserId = this.$store.getters.user.userId
                this.preStartupCheck.psrCheckStatusCd = 'PCSC000005'
  
                if (this.preStartupCheck.equipments && this.preStartupCheck.equipments.length > 0) {
                  this.preStartupCheck.equipments = this.$_.filter(this.preStartupCheck.equipments, null)
                }
                this.isComplete = !this.isComplete
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          }
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    completeCallback(result) {
      this.param.sopPrestartupCheckId = result.data
      this.saveCallData = uid();
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getPreStartupCheck();
    },
    removePreStartupCheck() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.param.sopPrestartupCheckId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    checklistPrint() {
      let thisVue = this;
      this.$http.url = this.$format(this.checklistPrintUrl, this.param.sopPrestartupCheckId);
      this.$http.type = 'GET';
      this.$http.request(
        _result => {
          let fileOrgNm = this.$language('가동전점검표')+'_' + this.preStartupCheck.checkName + '_'  + this.$comm.getToday() + '.xlsx';
          let blob = thisVue.$comm.base64ToBlob(_result.data);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileOrgNm);
          } else {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileOrgNm;
            link.click();
          }
        }, () => { }
      );
    },
    checklistReportPrint() {
      let thisVue = this;
      this.$http.url = this.$format(this.checklistReportPrintUrl, this.param.sopPrestartupCheckId);
      this.$http.type = 'GET';
      this.$http.request(
        _result => {
          let fileOrgNm = this.$language('가동전 안전점검 보고서')+'_' + this.preStartupCheck.checkName + '_'  + this.$comm.getToday() + '.xlsx';
          let blob = thisVue.$comm.base64ToBlob(_result.data);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileOrgNm);
          } else {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileOrgNm;
            link.click();
          }
        }, () => { }
      );
    },
  }
};
</script>
